import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { interval, Subscription, take, timer } from 'rxjs';
import { PinComponent } from '@components/pin/pin';
import { Timer } from '@enums/timer.enum';
import { TwoFactorSelectorName, TypeSendCode } from '@enums/two-factor.enum';
import { TranslationService } from '@providers/translation/translation.service';
import { RoleAssignment } from '@models/state/user.model';
import { SessiondataService } from '@providers/session-data/session-data.service';

@Component({
  selector: 'app-insert-code',
  templateUrl: './insert-code.component.html',
})
export class InsertCodeComponent implements OnChanges, OnDestroy {
  @Input() sendCodeSuccess = false;
  @Input() currentValue: string;
  @Input() messageError = '';
  @Input() roleAssignmented: RoleAssignment;
  @Input() verifyTwoFactor: boolean;
  @Input() verifyCodeSuccess = false;
  @Input() isPhone = TwoFactorSelectorName.phone;
  @Output() emitSendCode = new EventEmitter<any>();
  @Output() emitVerifyCode = new EventEmitter<any>();
  @Output() returnEmit = new EventEmitter<any>();

  email = '';
  codePresent = '----';
  codeString = '';
  pinComponent: PinComponent;
  countValidation = false;

  countdown = '02:00';
  contdown$: Subscription;
  finished$: Subscription;
  currentLang: string;
  langFromStore: string;

  constructor(
    private session: SessiondataService,
    private translation: TranslationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.sendCodeSuccess?.currentValue) {
      this.initCont();
    }
    if (changes?.verifyCodeSuccess?.currentValue) {
      this.pinComponent.cleanData();
    }
    if (changes?.messageError?.currentValue) {
      if (!this.messageError?.includes('.')) {
        this.countValidation = false;
      }
    }
  }

  validateField(event) {
    this.codeString = event.code;
    this.codePresent = event.codePresent;
    this.pinComponent = event.pin;
  }

  verifyCode() {
    this.emitVerifyCode.emit({
      codeString: this.codeString,
      codePresent: this.codePresent,
      activeManagementGroupId: this.session.activeManagementGroupId,
      roleAssignment: this.roleAssignmented,
    });
  }

  sendCode() {
    if (!this.countValidation) {
      const { alerts } = this.translation.languageMessage;
      let type;
      if (this.isPhone === TwoFactorSelectorName.phone) {
        type = TypeSendCode.PHONE;
      } else {
        type = TypeSendCode.EMAIL;
      }

      this.emitSendCode.emit({
        resend: alerts.reSendCode,
        to: this.currentValue,
        email: this.email,
        type,
      });
    }
  }

  initCont(): void {
    this.countValidation = true;
    const timerInterval$ = interval(Timer.INERTVAL_1000);
    const timer$ = timer(Timer.SECONS_120);
    const time = Timer.SECS_120;
    const { actions } = this.translation.languageMessage;
    const countDown$ = timerInterval$.pipe(take(time));
    this.contdown$ = countDown$.subscribe((val: any) => {
      const totalSeconds = time - val;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      this.countdown = `${this.padTo2Digits(minutes)}:${this.padTo2Digits(
        seconds
      )}`;
    });
    this.finished$ = timer$.subscribe(() => {
      this.countValidation = false;
      this.countdown = actions.send;
    });
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  cleanData(): void {
    this.pinComponent?.cleanData();
    this.codeString = '';
    this.codePresent = '----';
  }

  returnSelect(): void {
    this.cleanData();
    if (this.finished$) {
      this.contdown$.unsubscribe();
    }
    this.returnEmit.emit();
  }

  ngOnDestroy(): void {
    if (this.finished$) {
      this.contdown$.unsubscribe();
    }
  }
}
