import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { SubscriptionsService } from '@deprecated/services/subscription/subscription.service';
import {
  createSubscription,
  setCreateSubscriptionSuccess,
  getSubscription,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  updateSubscription,
  setUpdateSubscriptionSuccess,
  cancelSubscription,
  setCancelSubscriptionSuccess,
} from '@deprecated/state/actions/subscription.actions';
import { ResponseListSubscription } from '@models/responses/response-subscription';
import { Store } from '@ngrx/store';
import { AppState } from '@deprecated/state/app.state';
import { showSnackbar } from '@deprecated/state/actions/shared.actions';
import { ReSend } from '@models/entities/user';
import { ResponseGeneral } from '@models/responses/response-general';
import { ModalService } from '@providers/modal/modal.service';
import { NormalModalComponent } from '@components/normal-modal/normal-modal';
import { TranslationService } from '@providers/translation/translation.service';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';

@Injectable()
export class SubscriptionEffects {
  constructor(
    private actions$: Actions,
    private loading: LoaderManagmentService,
    private modals: ModalService,
    private subscriptionService: SubscriptionsService,
    private store: Store<AppState>,
    private translation: TranslationService
  ) {}

  getSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubscription),
      exhaustMap(action =>
        this.subscriptionService.getSubscriptions(action.managementGroup).pipe(
          map((response: ResponseListSubscription) => {
            const { success, data, message, description } = response;
            if (success) {
              return getSubscriptionSuccess({ subscription: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getSubscriptionFailure({ subscription: [] });
            }
          }),
          catchError((error: ReSend) => {
            const { title, message } = error;
            this.store.dispatch(showSnackbar({ title, message }));
            return of(getSubscriptionFailure({ subscription: [] }));
          })
        )
      )
    )
  );

  createSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSubscription),
      exhaustMap(action =>
        this.subscriptionService
          .createSubscription(action.newSubscription)
          .pipe(
            map((response: ResponseGeneral) => {
              const { success, message, description } = response;
              if (success) {
                return setCreateSubscriptionSuccess({ status: true });
              } else {
                this.loading.dismissLoading();
                return showSnackbar({ title: message, message: description });
              }
            }),
            catchError((error: ReSend) => {
              const { title, message } = error;
              this.loading.dismissLoading();
              return of(showSnackbar({ title, message }));
            })
          )
      )
    )
  );

  updateSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSubscription),
      exhaustMap(action =>
        this.subscriptionService.updateSubscription(action.updateData).pipe(
          map((response: ResponseGeneral) => {
            const { success, message, description } = response;
            this.loading.dismissLoading();
            if (success) {
              return setUpdateSubscriptionSuccess({ status: true });
            } else {
              return showSnackbar({ title: message, message: description });
            }
          }),
          catchError((error: ReSend) => {
            const { title, message } = error;
            this.loading.dismissLoading();
            return of(showSnackbar({ title, message }));
          })
        )
      )
    )
  );

  cancelSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelSubscription),
      exhaustMap(action =>
        this.subscriptionService.cancelSubscription(action.company).pipe(
          map((response: ResponseGeneral) => {
            const { success, message, description } = response;
            if (success) {
              const {
                actions: { understood },
              } = this.translation.languageMessage;
              this.modals.showAlertComp(NormalModalComponent, {
                title: message,
                message: description,
                btnSave: understood,
              });
              return setCancelSubscriptionSuccess({ status: true });
            } else {
              this.loading.dismissLoading();
              return showSnackbar({ title: message, message: description });
            }
          }),
          catchError((error: ReSend) => {
            this.loading.dismissLoading();
            if (error?.title && error?.message) {
              const {
                actions: { understood },
              } = this.translation.languageMessage;
              this.modals.showAlertComp(NormalModalComponent, {
                title: error.title,
                message: error.message,
                btnSave: understood,
              });
            }
            return of();
          })
        )
      )
    )
  );
}
