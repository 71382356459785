import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-info',
  templateUrl: './simple-info.component.html',
  styleUrls: ['./simple-info.component.scss'],
})
export class SimpleInfoComponent {
  @Input() classDiv: string = '';
  @Input() iconColor: string = 'light-purple';
  @Input() srcIcon: string = 'circle';
  @Input() info: string;
}
