import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULTLANGUAGE, objLangs } from '@data/i18n.data';
import { ModalService } from '@providers/modal/modal.service';
import { ModalsFullScreen } from '@enums/modals-fullscreen.enum';
import { TranslationService } from '@providers/translation/translation.service';

@Component({
  selector: 'lang-modal',
  templateUrl: './lang.html',
  styleUrls: ['./lang.scss'],
})
export class LangComponent implements OnInit {
  listOfLanguages = [];
  currentLang: string;
  langFromStore: string;

  constructor(
    private modalCtrl: ModalService,
    private translate: TranslateService,
    private translation: TranslationService
  ) {}

  ngOnInit() {
    this.currentLang = this.translation.currentlanguage || DEFAULTLANGUAGE;
    this.selectList(this.currentLang);
    this.langFromStore = this.currentLang;
  }

  selectList(currentCode) {
    this.setLangs(currentCode);
  }

  saveLang() {
    this.setLangs(this.currentLang);
    this.translate.use(this.currentLang);
    const currentObjLang = this.listOfLanguages.find(
      lang => lang.code === this.currentLang
    );
    this.modalCtrl.dismissModal(
      { lang: { ...currentObjLang, selected: true } },
      'cancel',
      ModalsFullScreen.LANGUAGE
    );
  }

  setLangs(langCode) {
    this.listOfLanguages = [];
    this.listOfLanguages = [...objLangs[langCode]];
  }

  closeModal() {
    this.modalCtrl.dismissModal(null, 'cancel', ModalsFullScreen.LANGUAGE);
  }
}
