import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NAV_LEVEL_LIST } from '@constants/navbar';
import { MenuNavbarUrl, NavBarListItem } from '@models/entities/navbar';
import { ModalService } from '@providers/modal/modal.service';
import { TranslationService } from '@providers/translation/translation.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.html',
  styleUrls: ['./navbar.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() menu: MatSidenav;
  currentNavAccessLevel: NavBarListItem;
  language;

  constructor(
    private modal: ModalService,
    private translation: TranslationService,
    private userManager: UserManagerService
  ) {}

  ngOnInit() {
    this.language = this.translation.languageMessage;
    this.currentNavAccessLevel =
      NAV_LEVEL_LIST[this.userManager.level || 'dev'];
  }

  toggleMenu() {
    if (this.menu.mode === 'over') {
      this.menu.toggle();
    }
  }

  inProcess(item: MenuNavbarUrl) {
    if (!item.enable) {
      const {
        labels: { inProcess },
        global: { inProcessD },
        actions: { understood },
      } = this.language;
      const data = {
        title: inProcess,
        message: inProcessD,
        btnSave: understood,
      };
      this.modal.showAlert(data);
    }
  }
}
