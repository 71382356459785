import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { StorageKeys } from '@enums/storage-keys.enum';
import { StorageService } from '@providers/storage/storage.service';
import { ipcRenderer } from 'electron';
import { AVAILABLELANGUAGES_ES, DEFAULTLANGUAGE } from '@data/i18n.data';
import { TranslationService } from '@providers/translation/translation.service';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { SharedService } from '@providers/shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  theme$: Observable<boolean>;

  title = 'passer-electron-autoUpdater';
  ipcRenderer: typeof ipcRenderer;
  messages: string;
  testingVar: boolean;

  constructor(
    private loading: LoaderManagmentService,
    private sharedService: SharedService,
    private storage: Storage,
    private storageService: StorageService,
    private translate: TranslateService,
    private translation: TranslationService
  ) {
    this.initProsess();
  }

  async initProsess(): Promise<void> {
    await this.themeLoader();
    await this.setLanguage();
  }

  async themeLoader() {
    await this.storage.create();
    const themeMode = await this.storageService.getData(StorageKeys.DARK_MODE);
    this.sharedService.changeTheme(!!themeMode);
    await this.storageService.setData(StorageKeys.DARK_MODE, !!themeMode);
  }

  async setLanguage(): Promise<void> {
    let userLang;
    try {
      const response = await this.storageService.getData(StorageKeys.LANG);
      userLang = response ?? DEFAULTLANGUAGE;
    } catch (e) {
      userLang = DEFAULTLANGUAGE;
    }

    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang(userLang);
    const lang = AVAILABLELANGUAGES_ES[userLang === DEFAULTLANGUAGE ? 1 : 0];
    this.translation.setLanguage(lang);
  }

  isLoading(): boolean {
    return this.loading.isLoading();
  }
}
