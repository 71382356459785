import { Injectable } from '@angular/core';
import { StatusDeleteChange } from '@enums/accessType.enum';
import { DashboardType } from '@enums/general-data.enum';
import {
  ResponseActions,
  ResponseChangeAccess,
  ResponseSystemRoleList,
  ResponseUserDataList,
  ResponseUserLog,
} from '@models/responses/response-access';
import { ResponseGeneral } from '@models/responses/response-general';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { ModalService } from '@providers/modal/modal.service';
import { TranslationService } from '@providers/translation/translation.service';
import { AccessSecurityService } from '@deprecated/services/access-security/access-security.service';
import {
  closeAllSessions,
  closeUserSession,
  deleteAccess,
  setStatusDeleteAccess,
  getActionsData,
  getActionsDataFailed,
  getActionsDataSuccess,
  getSystemRoleList,
  getSystemRoleListFailed,
  getSystemRoleListSuccess,
  getUserDataList,
  getUserDataListFailed,
  getUserDataListSuccess,
  getUserLogData,
  getUserLogDataFailed,
  getUserLogDataSuccess,
  showModalSuccess,
  changeAccess,
  setStatusChangeAccess,
} from '@deprecated/state/actions/access-security.actions';
import { showSnackbar } from '@deprecated/state/actions/shared.actions';
import { AppState } from '@deprecated/state/app.state';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class AccessSecurityEffects {
  constructor(
    private actions$: Actions,
    private accessSecurityService: AccessSecurityService,
    private loading: LoaderManagmentService,
    private modalCtrl: ModalService,
    private store: Store<AppState>,
    private translation: TranslationService
  ) {}

  getSystemRoleList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getSystemRoleList),
      exhaustMap(() => {
        return this.accessSecurityService.getSystemRoleList().pipe(
          map((response: ResponseSystemRoleList) => {
            const { data, success, message, description } = response;
            this.loading.dismissLoading();
            if (success) {
              const systemRoleDEAList = [];
              const systemRoleDESList = [];
              const {
                accessSecurity: {
                  accessManagement: { descriptionRoleDEA, descriptionRoleDES },
                },
              } = this.translation.languageMessage;
              let roleSelected;
              data.forEach(systemRole => {
                if (
                  systemRole.systemPlatform.platformName ===
                  DashboardType.DASHBOARD_ACCOUNT
                ) {
                  roleSelected = descriptionRoleDEA.find(
                    roleDEA => roleDEA.role === systemRole.id
                  );
                  systemRole.systemPlatform.description =
                    roleSelected.description;
                  systemRoleDEAList.push(systemRole);
                } else {
                  roleSelected = descriptionRoleDES.find(
                    roleDES => roleDES.role === systemRole.id
                  );
                  systemRole.systemPlatform.description =
                    roleSelected.description;
                  systemRoleDESList.push(systemRole);
                }
              });
              return getSystemRoleListSuccess({
                systemRoleDEAList,
                systemRoleDESList,
              });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getSystemRoleListFailed({
                systemRoleDEAList: [],
                systemRoleDESList: [],
              });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            this.store.dispatch(showSnackbar(errResp));
            return of(
              getSystemRoleListFailed({
                systemRoleDEAList: [],
                systemRoleDESList: [],
              })
            );
          })
        );
      })
    );
  });

  getUserList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUserDataList),
      exhaustMap(action => {
        return this.accessSecurityService.getUserDataList(action.params).pipe(
          map((response: ResponseUserDataList) => {
            const { data, success, message, description } = response;
            this.loading.dismissLoading();
            if (success) {
              data.users.forEach(dataUser => {
                if (!dataUser.userdata.avatar) {
                  dataUser.userdata.avatar = './assets/img/user.png';
                }
              });
              return getUserDataListSuccess({ userList: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getUserDataListFailed({ userList: null });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            this.store.dispatch(showSnackbar(errResp));
            return of(getUserDataListFailed({ userList: null }));
          })
        );
      })
    );
  });

  getUserLogData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUserLogData),
      exhaustMap(action => {
        return this.accessSecurityService.getUserLog(action.params).pipe(
          map((response: ResponseUserLog) => {
            const { data, success, message, description } = response;
            this.loading.dismissLoading();
            if (success) {
              return getUserLogDataSuccess({ userLogData: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getUserLogDataFailed({ userLogData: null });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            this.store.dispatch(showSnackbar(errResp));
            return of(getUserLogDataFailed({ userLogData: null }));
          })
        );
      })
    );
  });

  getActionsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getActionsData),
      exhaustMap(() => {
        return this.accessSecurityService.getActions().pipe(
          map((response: ResponseActions) => {
            const { data, success, message, description } = response;
            if (success) {
              return getActionsDataSuccess({ actionList: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getActionsDataFailed({ actionList: null });
            }
          }),
          catchError(errResp => {
            this.store.dispatch(showSnackbar(errResp));
            return of(getActionsDataFailed({ actionList: null }));
          })
        );
      })
    );
  });

  closeUserSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(closeUserSession),
      exhaustMap(action => {
        return this.accessSecurityService
          .closeUserSession(action.id, action.params)
          .pipe(
            map((response: ResponseGeneral) => {
              const { success, message, description } = response;
              this.loading.dismissLoading();
              if (success) {
                return showModalSuccess();
              } else {
                return showSnackbar({ title: message, message: description });
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  closeAllSessions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(closeAllSessions),
      exhaustMap(action => {
        return this.accessSecurityService
          .setCloseAllSessions(action.managementGroup, action.hasMfa)
          .pipe(
            map((response: ResponseGeneral) => {
              const { success, message, description } = response;
              this.loading.dismissLoading();
              if (success) {
                return showModalSuccess();
              } else {
                return showSnackbar({ title: message, message: description });
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  showModalSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[showModalSuccess]),
        tap(() => {
          const {
            accessSecurity: { accessRegister },
            actions,
          } = this.translation.languageMessage;
          this.modalCtrl.showAlert({
            title: accessRegister.actionSuccessTitle,
            message: accessRegister.actionSuccessDescription,
            btnSave: actions.understood,
          });
        })
      );
    },
    { dispatch: false }
  );

  deleteAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteAccess),
      exhaustMap(actions => {
        return this.accessSecurityService.deleteAccess(actions.id).pipe(
          map((response: ResponseActions) => {
            const { success, message, description } = response;
            if (success) {
              return setStatusDeleteAccess({
                statusDelete: StatusDeleteChange.SUCCESS,
              });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return setStatusDeleteAccess({
                statusDelete: StatusDeleteChange.FAILED,
              });
            }
          }),
          catchError(errResp => {
            this.store.dispatch(showSnackbar(errResp));
            return of(
              setStatusDeleteAccess({ statusDelete: StatusDeleteChange.FAILED })
            );
          })
        );
      })
    );
  });

  changeAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeAccess),
      exhaustMap(actions => {
        return this.accessSecurityService
          .changeAccess(actions.params, actions.id)
          .pipe(
            map((response: ResponseChangeAccess) => {
              const { data, success, message, description } = response;
              if (success) {
                return setStatusChangeAccess({
                  statusChange: StatusDeleteChange.SUCCESS,
                  data: {
                    accessRolesDes: data.accessRolesDes,
                    userdataRole: data.userdataRole,
                  },
                });
              } else {
                this.store.dispatch(
                  showSnackbar({ title: message, message: description })
                );
                return setStatusChangeAccess({
                  statusChange: StatusDeleteChange.FAILED,
                  data: null,
                });
              }
            }),
            catchError(errResp => {
              this.store.dispatch(showSnackbar(errResp));
              return of(
                setStatusChangeAccess({
                  statusChange: StatusDeleteChange.FAILED,
                  data: null,
                })
              );
            })
          );
      })
    );
  });
}
