import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeCR from '@angular/common/locales/es-CR';
import localeExtraCR from '@angular/common/locales/extra/es-CR';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Drivers } from '@ionic/storage';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MaterialModule } from './app-material.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { passerNavAnimation } from '@animations/nav-animation';
import { ROOT_REDUCERS } from '@deprecated/state/app.state';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '@deprecated/state/effects/auth.effects';
import { SharedEffects } from '@deprecated/state/effects/shared.effects';

import { CodeEffects } from '@deprecated/state/effects/code.effects';
import { ErrorInterceptor } from '@providers/error-interceptor/error-interceptor.service';
import { ComponentsModule } from '@components/components.module';
import { SetDateStringService } from '@providers/set-date-string/set-date-string';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { CatalogsEffects } from '@deprecated/state/effects/catalogs.effects';

import { PaginationService } from 'ngx-pagination';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { PaymentMethodEffects } from '@deprecated/state/effects/payment-method.effects';
import { FormatPipe } from '@pipes/format';
import { PasserCryptoService } from '@providers/passer-crypto/passer-crypto.service';
import { AuthInterceptor } from '@providers/auth-interceptor/auth-interceptor.provider';
import { SubscriptionEffects } from '@deprecated/state/effects/subscription.effects';
import { TransactionsEffects } from '@deprecated/state/effects/transactions.effects';
import { AccessSecurityEffects } from '@deprecated/state/effects/access-security.effects';
import { CreditCardPipe } from '@pipes/format-creditcard';
import { CredentialsEffects } from '@deprecated/state/effects/credentials.effects';

export const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, 'assets/i18n/', '.json');
registerLocaleData(localeCR, localeExtraCR);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      backButtonText: '',
      sanitizerEnabled: true,
      navAnimation: passerNavAnimation,
    }),
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IonicStorageModule.forRoot({
      name: '__dea',
      driverOrder: [
        Drivers.IndexedDB,
        Drivers.LocalStorage,
        Drivers.SecureStorage,
      ],
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(ROOT_REDUCERS),
    StoreDevtoolsModule.instrument({ name: 'DEA' }),
    EffectsModule.forRoot([
      AccessSecurityEffects,
      AuthEffects,
      CatalogsEffects,
      CredentialsEffects,
      CodeEffects,
      PaymentMethodEffects,
      SharedEffects,
      SubscriptionEffects,
      TransactionsEffects,
    ]),
  ],
  providers: [
    SetDateStringService,
    PaginationService,
    MethodsGeneralService,
    FormatPipe,
    PasserCryptoService,
    CreditCardPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CR' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
