import { exhaustMap, map, catchError, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import {
  loginStart,
  loginSuccess,
  autoLogin,
  changePosition,
  autoLoginSuccess,
  recoverPassword,
  recoverRedirect,
  resetPassword,
  resetPasswordRedirect,
  setAccessLevel,
  logOut,
  logoutSuccess,
  userManagementGroup,
  userRoleAssignment,
  autoLogout,
  userInfo,
  refreshToken,
  recoverMessage,
  suspendedAccessLogOut,
  userManagementGroupRedirect,
  updateUserAccount,
  updateUserAccountSuccess,
  autoRefreshTokenSuccess,
  twoFactorData,
  deleteAccessGroup,
  deleteChangeAccessGroupSuccess,
  setManagementGroups,
} from '@deprecated/state/actions/auth.actions';
import { setErrorMessage, showSnackbar } from '@deprecated/state/actions/shared.actions';
import { AppState } from '@deprecated/state/app.state';
import { AuthService } from '@deprecated/services/auth/auth.service';
import { StorageService } from '@providers/storage/storage.service';
import { StorageKeys } from '@enums/storage-keys.enum';
import { IdPAccessTokenResponse } from '@models/responses/response-login';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { ResponseGeneral } from '@models/responses/response-general';
import { LoginSlider } from '@enums/slider.enum';
import {
  ManagementGroup,
  RoleAssignment,
  User,
} from '@models/state/user.model';
import { ROUTES_ADMIN } from '@constants/routes';
import { RequestResponse } from '@enums/account-products.enum';
import { ModalService } from '@providers/modal/modal.service';
import { clearDataCode, setActiveGroup } from '@deprecated/state/actions/code.actions';
import { clearDataPayments } from '@deprecated/state/actions/payment-method.actions';
import { clearDataAccess } from '@deprecated/state/actions/access-security.actions';
import { clearDataCredentials } from '@deprecated/state/actions/credentials.actions';
import { clearDataSubs } from '@deprecated/state/actions/subscription.actions';
import { clearDataTransactions } from '@deprecated/state/actions/transactions.actions';
import { NormalModalComponent } from '@components/normal-modal/normal-modal';
import { TranslationService } from '@providers/translation/translation.service';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private loading: LoaderManagmentService,
    private modalService: ModalService,
    private store: Store<AppState>,
    private router: Router,
    private storage: StorageService,
    private session: SessiondataService,
    private translation: TranslationService
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginStart),
      exhaustMap(action => {
        return this.authService.login(action.email, action.password).pipe(
          map((response: IdPAccessTokenResponse) => {
            this.session.accessToken = response;
            return userInfo({ access_token: response });
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar(errResp));
          })
        );
      })
    );
  });

  userInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...[userInfo]),
      exhaustMap(action => {
        return this.authService.userInfo().pipe(
          map((response: User) => {
            return userManagementGroup({
              user: response,
              access_token: action.access_token,
            });
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar(errResp));
          })
        );
      })
    );
  });

  userManagementGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userManagementGroup),
      exhaustMap(action => {
        const {
          global: { passer_error_title, passer_error_cache },
        } = this.translation.languageMessage;

        if (!action.user) {
          this.loading.dismissLoading();
          return of(
            showSnackbar({
              title: passer_error_title,
              message: passer_error_cache,
              duration: 5,
            })
          );
        }

        return this.authService.userManagementGroupsInfo(action.user.sub).pipe(
          map((response: ManagementGroup[]) => {
            if (response.length == 0) {
              this.loading.dismissLoading();
              return showSnackbar({
                title: passer_error_title,
                message: passer_error_cache,
                duration: 5,
              });
            }
            this.storage.setData(StorageKeys.MANAGEMENT_GROUPS, response);
            if (response.length > 1 && !action.deleteAccess) {
              return userManagementGroupRedirect({
                managementGroups: response,
                user: action.user,
              });
            } else {
              this.store.dispatch(
                setManagementGroups({ managementGroups: response })
              );
              const activeManagementGroupId = action.deleteAccess
                ? action.deleteAccess
                : response[0]._id;
              return userRoleAssignment({
                activeManagementGroupId,
                user: action.user,
                access_token: action.access_token,
                deleteAccess: !!action.deleteAccess,
              });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar(errResp));
          })
        );
      })
    );
  });

  userManagementGroupRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[userManagementGroupRedirect]),
        tap(() => {
          this.loading.dismissLoading();
          this.store.dispatch(
            changePosition({
              pagePosition: {
                previous: LoginSlider.login,
                current: LoginSlider.selectGroup,
              },
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  userRoleAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userRoleAssignment),
      exhaustMap(action => {
        const {
          global: {
            passer_error_title,
            passer_idp_error_invalid_management_group,
            passer_error_cache,
          },
        } = this.translation.languageMessage;

        if (!action.activeManagementGroupId) {
          this.loading.dismissLoading();
          return of(
            showSnackbar({
              title: passer_error_title,
              message: passer_idp_error_invalid_management_group,
              duration: 5,
            })
          );
        }

        if (!action.user) {
          this.loading.dismissLoading();
          return of(
            showSnackbar({
              title: passer_error_title,
              message: passer_error_cache,
              duration: 5,
            })
          );
        }

        return this.authService
          .userRoleAssignment(action.activeManagementGroupId, action.user.sub)
          .pipe(
            map((response: RoleAssignment[]) => {
              const roleAssignmented = response.find(
                roleAssignmented =>
                  roleAssignmented.scope === action.activeManagementGroupId
              );
              if (!roleAssignmented) {
                this.loading.dismissLoading();
                return showSnackbar({
                  title: passer_error_title,
                  message: passer_idp_error_invalid_management_group,
                  duration: 5,
                });
              }

              if (roleAssignmented.roleDefinition) {
                return refreshToken({
                  user: action.user,
                  activeManagementGroupId: action.activeManagementGroupId,
                  roleAssignment: roleAssignmented,
                  redirect: true,
                  refresh_token: action.access_token.refresh_token,
                  updateManagementGroup: action.updateManagementGroup,
                  deleteAccess: action.deleteAccess,
                });
              } else {
                this.loading.dismissLoading();
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  refreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshToken),
      exhaustMap(action => {
        return this.authService
          .refresh(action.refresh_token, action.activeManagementGroupId)
          .pipe(
            map((response: IdPAccessTokenResponse) => {
              this.session.accessToken = response;
              return loginSuccess({
                user: action.user,
                activeManagementGroupId: action.activeManagementGroupId,
                roleAssignment: action.roleAssignment,
                redirect: true,
                updateManagementGroup: action.updateManagementGroup,
                verifyCode: false,
                deleteAccess: action.deleteAccess,
              });
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  autoRefreshTokenSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(autoRefreshTokenSuccess),
        tap(async action => {
          this.session.accessToken = action.response;
          await this.storage.setData(
            StorageKeys.TOKEN,
            this.session.accessToken
          );
        })
      );
    },
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[loginSuccess]),
        tap(async action => {
          const { activeManagementGroupId, roleAssignment } = action;
          this.session.activeManagementGroupId = activeManagementGroupId;
          this.session.idpSub = action?.user?.sub;
          if (
            action.user?.hasMfa &&
            !action.updateManagementGroup &&
            !action.verifyCode &&
            !action.deleteAccess
          ) {
            this.store.dispatch(
              twoFactorData({
                user: action.user,
                activeManagementGroupId: action.activeManagementGroupId,
                roleAssignment: action.roleAssignment,
              })
            );
            this.store.dispatch(
              setActiveGroup({ activeManagementGroupId, roleAssignment })
            );
            this.store.dispatch(
              changePosition({
                pagePosition: {
                  previous: LoginSlider.login,
                  current: LoginSlider.twoFactor,
                },
              })
            );
            this.loading.dismissLoading();
          } else {
            await this.storage.setData(
              StorageKeys.TOKEN,
              this.session.accessToken
            );
            await this.storage.setData(
              StorageKeys.ACTIVE_MANAGEMENT_GROUP,
              activeManagementGroupId
            );
            await this.storage.setData(
              StorageKeys.ROLE_ASSIGNMENT,
              roleAssignment
            );
            if (!action.updateManagementGroup && !action.deleteAccess) {
              await this.storage.setData(StorageKeys.USER, action.user);
              await this.storage.setData(
                StorageKeys.IS_LOGGED_IN,
                this.session.accessToken ? true : false
              );
              if (this.session.accessToken !== undefined) {
                this.router.navigate([ROUTES_ADMIN.ROOT_ROUTE], {
                  replaceUrl: true,
                });
              }
              this.loading.dismissLoading();
            } else {
              this.store.dispatch(
                deleteChangeAccessGroupSuccess({
                  deleteAccess: action.deleteAccess,
                  reload: true,
                })
              );
            }
          }
        })
      );
    },
    { dispatch: false }
  );

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(autoLogin),
      exhaustMap(async () => {
        const [
          user,
          isLoggedIn,
          token,
          roleAssignment,
          activeManagementGroup,
          managementGroups,
        ]: any = await Promise.all([
          this.storage.getData(StorageKeys.USER),
          this.storage.getData(StorageKeys.IS_LOGGED_IN),
          this.storage.getData(StorageKeys.TOKEN),
          this.storage.getData(StorageKeys.ROLE_ASSIGNMENT),
          this.storage.getData(StorageKeys.ACTIVE_MANAGEMENT_GROUP),
          this.storage.getData(StorageKeys.MANAGEMENT_GROUPS),
        ]);
        this.session.isLoggedIn = isLoggedIn ?? false;
        this.session.accessToken = token;
        this.session.activeManagementGroupId = activeManagementGroup;
        this.session.idpSub = user?.sub;
        this.store.dispatch(
          setAccessLevel({
            level: roleAssignment?.roleDefinition?.roleName.toLowerCase(),
            levelName: roleAssignment?.roleDefinition?.displayName || '-',
            activeManagementGroup,
            managementGroups,
          })
        );

        return autoLoginSuccess({ user, redirect: false });
      })
    );
  });

  recover$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(recoverPassword),
      exhaustMap(action => {
        return this.authService.recoverPassword(action.email).pipe(
          map((response: ResponseGeneral) => {
            const { success, message } = response;
            this.loading.dismissLoading();
            return recoverMessage({ success, message });
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar(errResp));
          })
        );
      })
    );
  });

  recoverRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[recoverRedirect]),
        tap(() => {
          this.store.dispatch(
            changePosition({
              pagePosition: {
                previous: LoginSlider.recoverPassword,
                current: LoginSlider.sendCode,
              },
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetPassword),
      exhaustMap(action => {
        return this.authService
          .resetPassword(action.password, action.confirmPassword)
          .pipe(
            map((response: ResponseGeneral) => {
              const { success, message, description } = response;
              this.loading.dismissLoading();
              if (success) {
                let newToken: IdPAccessTokenResponse;
                this.session.accessToken = newToken;
                this.store.dispatch(setErrorMessage({ message: '' }));
                this.store.dispatch(
                  showSnackbar({ title: message, message: description })
                );
                return resetPasswordRedirect();
              } else {
                this.store.dispatch(setErrorMessage({ message }));
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  resetPasswordRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[resetPasswordRedirect]),
        tap(() => {
          this.store.dispatch(
            changePosition({
              pagePosition: {
                previous: LoginSlider.newPassword,
                current: LoginSlider.login,
              },
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logOut),
      exhaustMap(async action => {
        const user: User = await this.storage.getData(StorageKeys.USER);
        const managementGroup: string = await this.storage.getData(
          StorageKeys.ACTIVE_MANAGEMENT_GROUP
        );
        return autoLogout({
          user,
          redirect: true,
          managementGroup,
          unauthorized: action.unauthorized,
        });
      })
    );
  });

  autoLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(autoLogout),
      exhaustMap(action => {
        return this.authService
          .logout(action.user.sub, { managementGroup: action.managementGroup })
          .pipe(
            map((response: ResponseGeneral) => {
              const { success, message } = response;
              if (success) {
                return logoutSuccess({ unauthorized: action.unauthorized });
              } else {
                this.store.dispatch(setErrorMessage({ message }));
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  logoutRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[logoutSuccess, suspendedAccessLogOut]),
        tap(async action => {
          const response: boolean = await this.storage.clearAllData();
          if (response) {
            this.loading.dismissLoading();
            await this.router.navigate([ROUTES_ADMIN.LOGIN_ROUTE], {
              replaceUrl: true,
            });
            this.modalService.closeAllModal();
            this.modalService.closeCurrentModals();
            // TODO - CHANGE WHEN DELETE REDUX
            this.store.dispatch(clearDataPayments());
            this.store.dispatch(clearDataAccess());
            this.store.dispatch(clearDataCode());
            this.store.dispatch(clearDataCredentials());
            this.store.dispatch(clearDataSubs());
            this.store.dispatch(clearDataTransactions());
            if (action.unauthorized) {
              const {
                global: { passerErrorTitle2, passerErrorMessage2 },
                actions: { understood },
              } = this.translation.languageMessage;
              this.modalService.showAlertComp(NormalModalComponent, {
                title: passerErrorTitle2,
                message: passerErrorMessage2,
                btnSave: understood,
              });
            }
          } else {
            // No deberia entrar acá
            this.loading.dismissLoading();
            const {
              global: { passer_error_title, passer_error_cache },
            } = this.translation.languageMessage;
            this.store.dispatch(
              showSnackbar({
                title: passer_error_title,
                message: passer_error_cache,
              })
            );
          }
        })
      );
    },
    { dispatch: false }
  );

  updateUserAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUserAccount),
      exhaustMap(action => {
        const { params, user } = action;
        return this.authService.updateUserData(params).pipe(
          map((response: ResponseGeneral) => {
            const { success, message, description } = response;
            if (success) {
              return updateUserAccountSuccess({
                status: RequestResponse.SUCCESS,
                user: {
                  ...user,
                  name: `${params.given_name} ${params.family_name}`,
                  email: params.email,
                  given_name: params.given_name,
                  family_name: params.family_name,
                  birthdate: params.birthdate,
                  sex: params.sex,
                  hasMfa: params.hasMfa,
                  phone: params.phone,
                },
              });
            } else {
              this.loading.dismissLoading();
              return showSnackbar({ title: message, message: description });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar(errResp));
          })
        );
      })
    );
  });

  updateUserAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[updateUserAccountSuccess]),
        tap(async action => {
          if (action.status === RequestResponse.SUCCESS) {
            const {
              actions: { successAction },
              accountProduct: { account },
            } = this.translation.languageMessage;
            this.store.dispatch(
              showSnackbar({
                title: successAction,
                message: account.accountUpdate,
              })
            );
            await this.storage.setData(StorageKeys.USER, action.user);
          }
        })
      );
    },
    { dispatch: false }
  );

  deleteAccessGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteAccessGroup),
      exhaustMap(action => {
        const {
          activeManagement,
          access_token,
          activeManagementGroupId,
          id,
          user,
        } = action;
        return this.authService.deleteAccessGroup(id).pipe(
          map((response: ResponseGeneral) => {
            const { success, message, description } = response;
            if (success) {
              if (activeManagement) {
                return deleteChangeAccessGroupSuccess({
                  deleteAccess: true,
                  reload: false,
                });
              } else {
                return userManagementGroup({
                  access_token,
                  user,
                  deleteAccess: activeManagementGroupId,
                });
              }
            } else {
              this.loading.dismissLoading();
              return showSnackbar({ title: message, message: description });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar(errResp));
          })
        );
      })
    );
  });

  deleteChangeAccessGroupSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[deleteChangeAccessGroupSuccess]),
        tap(async action => {
          const { deleteAccess, reload } = action;
          this.loading.dismissLoading();
          await this.storage.removeData(StorageKeys.MISSING_SUBSCRIPTION);
          const {
            actions: { successAction, understood },
            global: { changeManagementGroup, deleteManagementGroup },
          } = this.translation.languageMessage;
          await this.modalService.showAlert({
            title: successAction,
            message: deleteAccess
              ? deleteManagementGroup
              : changeManagementGroup,
            btnSave: understood,
          });
          if (deleteAccess && !reload) {
            this.store.dispatch(logOut({ unauthorized: false }));
          } else {
            if (
              this.router.url !==
              ROUTES_ADMIN.ACCOUNT_PRODUCTS_SUB_ROUTE.ACCOUNT
            ) {
              await this.router.navigate([ROUTES_ADMIN.ROOT_ROUTE], {
                replaceUrl: true,
              });
            }
            location.reload();
          }
        })
      );
    },
    { dispatch: false }
  );
}
