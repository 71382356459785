/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable } from '@angular/core';
import { DEFAULTLANGUAGE } from '@data/i18n.data';
import { StorageKeys } from '@enums/storage-keys.enum';
import { LangState } from '@models/state/lang.state';
import { StorageService } from '@providers/storage/storage.service';

declare let require: any;

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _languageData: LangState;
  private _languageMessage: any;
  private _currentlanguage = '';

  constructor(private storage: StorageService) {
    this._languageMessage = this.getJsonLanguages(this._currentlanguage);
  }

  getJsonLanguages(lang): any {
    switch (lang) {
      case 'es':
        return require('@assets/i18n/es.json');
      case 'en':
        return require('@assets/i18n/en.json');
      default:
        return require('@assets/i18n/es.json');
    }
  }

  async setLanguage(language: LangState): Promise<void> {
    this._languageData = language;
    this._currentlanguage = language?.code || DEFAULTLANGUAGE;
    this._languageMessage = this.getJsonLanguages(this.currentlanguage);
    const html = document.querySelector('html');
    html.setAttribute('lang', this._currentlanguage);
    await this.storage.setData(StorageKeys.LANG, this._currentlanguage);
  }

  // GET's
  public get currentlanguage(): string {
    return this._currentlanguage;
  }

  public get languageMessage(): any {
    return this._languageMessage;
  }

  public get languageData(): LangState {
    return this._languageData;
  }

  // SET's
  public set currentlanguage(language: string) {
    this._currentlanguage = language;
  }

  public set languageData(value: LangState) {
    this._languageData = value;
  }
}
