// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SERVER_URL: 'https://testdeaapi.passer.cr',
  serverUrlPasser: 'https://testapi.passer.cr',
  serverUrlPasserPolicy: 'https://testing.passer.live/legal/business-partner',
  serverUrlPasserPublicTransp: 'https://testing.passer.live/enterprises',
  serverUrlCatalogs: 'https://testcatalogs.passer.cr/api',
  serverUrlDriver: 'https://testdriverapi.passer.cr/api',
  serverLambda: 'https://functionsapi.passer.live/develop',
  socket_url: 'https://testmapsocket.passer.cr',
  socket_ticket_url: 'https://testsockets.passer.cr',
  socket_print_url: 'https://testprintsocket,passer.cr',
  passer_connect: 'https://testenterprisesconnect.passer.cr/doc/',
  enviromentCrypto: 'test',
  passerIdP: {
    options: {
      serverUrl: 'https://testidp.passer.live',
      clientId: 'dae',
      clientSecret: 'bar',
      scope: 'openid profile email phone offline_access',
      resource: 'https://testdeaapi.passer.cr',
      callbackRecoveryPasswordBaseUrl:
        'https://testing.passer.live/reset-password',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
