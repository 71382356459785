import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageKeys } from '@enums/storage-keys.enum';
import { RoleAssignment, User } from '@models/state/user.model';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { StorageService } from '@providers/storage/storage.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { LoginService } from '@endpoints/login.service';

@Component({
  selector: 'app-confirm-credentials',
  templateUrl: './confirm-credentials.component.html',
})
export class ConfirmCredentialsComponent implements OnInit {
  passwordControl = new FormControl('', [Validators.required]);
  eyePass = true;
  email = '';
  isSkeletonPresent = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private loading: LoaderManagmentService,
    private loginService: LoginService,
    private sessionData: SessiondataService,
    private storage: StorageService,
    private userManagerService: UserManagerService
  ) {}

  ngOnInit(): void {
    this.email = this.userManagerService.email;
  }

  closeModal(close = false): void {
    if (!close) {
      this.dialogRef.close();
      return;
    }

    if (this.passwordControl.invalid) {
      this.passwordControl.markAllAsTouched();
      return;
    }

    this.reEnterLogin();
  }

  async reEnterLogin(): Promise<void> {
    if (this.loading.isSkeleton()) {
      this.isSkeletonPresent = true;
      this.loading.dismissSkeleton();
    }
    this.loading.presentLoading();
    const response = await this.loginService.initLogin(
      this.email,
      this.passwordControl.value
    );
    const { success, data } = response;
    if (success) {
      this.sessionData.accessToken = data;
      await this.userInfo();
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async userInfo(): Promise<void> {
    const response = await this.loginService.loginUserInfo();
    const { success, data } = response;
    if (success) {
      await this.reviewSelectGroup(data);
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async reviewSelectGroup(user: User): Promise<void> {
    const response = await this.loginService.loginManagementGroupsInfo(
      user?.sub
    );
    const { success, data } = response;
    if (success) {
      this.storage.setData(StorageKeys.MANAGEMENT_GROUPS, response);
      if (data.length > 1) {
        const { activeManagementGroupId } = this.sessionData;
        const managementGroupd = data.find(
          group => group._id === activeManagementGroupId
        );
        this.sessionData.activeManagementGroupId = managementGroupd?._id;
      }
      if (this.sessionData.activeManagementGroupId) {
        await this.roleAssigment(
          this.sessionData.activeManagementGroupId,
          user
        );
      } else {
        this.loading.dismissLoading();
        this.closeModal();
      }
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async roleAssigment(activeGroup: string, user: User): Promise<void> {
    const response = await this.loginService.loginUserRoleAssignment(
      activeGroup,
      user?.sub
    );
    const { success, data } = response;
    if (success) {
      await this.refreshToken(activeGroup, data, user);
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async refreshToken(
    activeGroup: string,
    roleAssignmented: RoleAssignment,
    user: User
  ): Promise<void> {
    const response = await this.loginService.logingResfreshToken(activeGroup);
    if (response) {
      await this.loginSuccess(activeGroup, roleAssignmented, user);
    } else {
      this.loading.dismissLoading();
      this.closeModal();
    }
  }

  async loginSuccess(
    activeGroup: string,
    roleAssignmented: RoleAssignment,
    user: User
  ): Promise<void> {
    this.sessionData.activeManagementGroupId = activeGroup;
    this.sessionData.idpSub = user?.sub;
    await this.storage.setData(StorageKeys.TOKEN, this.sessionData.accessToken);
    await this.storage.setData(StorageKeys.ROLE_ASSIGNMENT, roleAssignmented);
    await this.storage.setData(StorageKeys.USER, user);
    if (this.isSkeletonPresent) {
      this.loading.presentSkeleton();
      this.isSkeletonPresent = false;
    }
    this.loading.dismissLoading();
    this.dialogRef.close(true);
  }
}
